<template>
  <div>
    <div v-if="detectionDisabled && activeUser && !isClient" class="alert alert-danger text-center" style="position: absolute; bottom: 0; left: 0;">You have disabled accessing your location. Please enable it and reload the page.</div>
  </div>
</template>
<script>
import Vue from 'vue'
import api from '@/shared/apis/profile.js'

export default {
  props: {
    frequency: {
      type: Number,
      default: 60
    },
    liveUpdate: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      currentLocation: { lat: 0, lng: 0 },
      detectionDisabled: false,
      pollingEnabled: false,
      polling: null
    }
  },
  beforeDestroy () {
    if (this.polling) {
      clearInterval(this.polling)
    }
  },
  mounted() {
    this.getLocation()
    this.setupPolling()
    this.pollingEnabled = true
  },
  computed: {
    userId() {
      if (this.activeUser) {
        return this.activeUser.id
      }
    }
  },
  methods: {
    getLocation() {
      if (this.pollingEnabled) {
        this.geolocation()
      }
    },
    geolocation() {
      this.pollingEnabled = false
      try {
        navigator.geolocation.getCurrentPosition((location) => {
          this.currentLocation.lat = location.coords.latitude,
          this.currentLocation.lng = location.coords.longitude
          this.pollingEnabled = true
        }, (error) => {
          this.detectionDisabled = true
          api.setProfileLocationDetectionDisabled()
        })
      } catch(e) {
        this.detectionDisabled = true
        api.setProfileLocationDetectionDisabled()
      }
    },
    setupPolling() {
      if (this.liveUpdate && (this.polling == null) && this.userId && (this.isClient || this.isCleaner) ) {
        //// update location at every frequency seconds
        this.polling = setInterval(this.getLocation, this.frequency * 1000)
      }
    },
    updateLocation() {
      api.addPosition(this.currentLocation).then(() => {
        this.pollingEnabled = true
      }).catch((e) => {
        this.pollingEnabled = true
      })
    },
  },
  watch: {
    currentLocation: {
      deep: true,
      handler(newValue, oldValue) {
        this.updateLocation()
      }
    },
    liveUpdate() {
      this.setupPolling()
    },
    activeUser(newValue, oldValue) {
      if (oldValue == null && newValue) {
        this.getLocation()
        this.setupPolling()
      }
    }
  }
}
</script>
